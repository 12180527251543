import { GA_EVENT } from './ga_event.modal'

const useGoogleAnalyticsEvent = () => {
  return (eventName: GA_EVENT, params?: Object, window?: any) => {
    //   ReactGA4.gtag('event', eventName, params)

    if (window && window?.dataLayer) {
      window?.dataLayer?.push({
        event: eventName,
        page: params,
      })
    }
  }
}

export { useGoogleAnalyticsEvent }
