import { FC } from 'react'
import { useUI } from '@components/ui/context'

const AlertRibbon: FC = () => {

  const {alertRibbon} = useUI()

  const color = (type:string) => {
    switch(type){
      case 'error':
        return 'bg-ribbon-red capitalize'
        
      case 'success':
        return 'bg-ribbon-green capitalize'
      
      case 'cancel':
        return 'bg-ribbon-cancel'
    }  
  }
    return (
      <div className={`${color(alertRibbon?.type) } justify-center w-full px-10 py-4 text-center align-center sticky top-24 z-50`}>
            <h4 className={`text-16 font-medium ${alertRibbon?.type == 'cancel' ? 'text-[#c10000]' : 'text-[#fff]'}`}>{alertRibbon?.msg}</h4>
      </div>
    )
}

export default AlertRibbon